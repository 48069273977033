


import React, { useState, useEffect, useRef } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import responses from "../data/responses.json";
import { v4 as uuidv4 } from "uuid";
import TextToAudio from "./TextToAudio";

const ChatAudioFile = () => {
	const [messages, setMessages] = useState([]);
	const [input, setInput] = useState("");
	const [recognition, setRecognition] = useState(null);
	const [isRecording, setIsRecording] = useState(false);
	const [isTyping, setIsTyping] = useState(false);
	const [isSending, setIsSending] = useState(false);
	const [playingMessageId, setPlayingMessageId] = useState(null); // Track which message is currently playing

	const [voices, setVoices] = useState([]);
	const [voiceURI, setVoiceURI] = useState("en-US");
	const [pitch, setPitch] = useState(1);
	const [rate, setRate] = useState(1.1);
	const [volume, setVolume] = useState(1);
    // const [isRecording, setIsRecording] = useState(false);
    const [recordingTime, setRecordingTime] = useState(0);

	const chatBoxRef = useRef(null);

	// useEffect(() => {
	// 	const SpeechRecognition =
	// 		window.SpeechRecognition || window.webkitSpeechRecognition;
	// 	if (SpeechRecognition) {
	// 		const recognitionInstance = new SpeechRecognition();
	// 		recognitionInstance.interimResults = true;
	// 		recognitionInstance.lang = "en-US";

	// 		recognitionInstance.onresult = (event) => {
	// 			const transcript = event.results[event.resultIndex][0].transcript;
	// 			setInput(transcript);
	// 		};

	// 		setRecognition(recognitionInstance);
	// 	} else {
	// 		alert("Speech recognition is not supported in this browser.");
	// 	}
	// }, []);

  // useEffect(() => {
  //   const SpeechRecognition =
  //     window.SpeechRecognition || window.webkitSpeechRecognition;
  //   if (SpeechRecognition) {
  //     const recognitionInstance = new SpeechRecognition();
  //     recognitionInstance.interimResults = true;
  //     recognitionInstance.lang = "en-US";
  //     recognitionInstance.continuous = true; // Keep listening even after pauses
  
  //     let finalTranscript = ""; // To hold the final combined sentence
  
  //     recognitionInstance.onresult = (event) => {
  //       let interimTranscript = "";
  //       for (let i = event.resultIndex; i < event.results.length; i++) {
  //         interimTranscript += event.results[i][0].transcript;
  //       }
  
  //       // Only append the interim transcript to the final one if it's a final result
  //       if (event.results[event.resultIndex].isFinal) {
  //         finalTranscript += interimTranscript.trim() + " ";
  //         setInput(finalTranscript.trim()); // Update the combined sentence
  //       }
  //     };
  
  //     recognitionInstance.onend = () => {
  //       if (isRecording) {
  //         recognitionInstance.start();
  //       }
  //     };
  
  //     setRecognition(recognitionInstance);
  //   } else {
  //     alert("Speech recognition is not supported in this browser.");
  //   }
  // }, []);

	useEffect(() => {
		const SpeechRecognition =
			window.SpeechRecognition || window.webkitSpeechRecognition;
		if (SpeechRecognition) {
			const recognitionInstance = new SpeechRecognition();
			recognitionInstance.interimResults = true;
			recognitionInstance.lang = "en-US";

			recognitionInstance.onresult = (event) => {
				const transcript = event.results[event.resultIndex][0].transcript;
				setInput(transcript);
			};

			setRecognition(recognitionInstance);
		} else {
			alert("Speech recognition is not supported in this browser.");
		}
	}, []);
  

	useEffect(() => {
		setMessages([
			{
				text: "Welcome to Nexuschat! How can I help you today?",
				sender: "bot",
			},
		]);
	}, []);

	useEffect(() => {
		if (chatBoxRef.current) {
			chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
		}
	}, [messages]);

	const handleSendMessage = async () => {
		if (input.trim()) {
      const modifiedInput = input.replace(/symphony/gi, "symphony");

			const userMessage = { text: modifiedInput, sender: "user" };
			setMessages((prev) => [...prev, userMessage]);

			setIsSending(true);
			setIsTyping(true);
      setInput("");
			try {
				const messageId = uuidv4();
				setMessages((prev) => [
					...prev,
					{ id: messageId, text: "...", sender: "bot" },
				]);
        // const text = `To install Doctrine in a Symfony project, follow these steps:

        // 1. Add the Doctrine ORM and Doctrine Migrations bundles to your \`composer.json\` file:
        // \`\`\`json
        // "require": {
        //     "doctrine/orm-bundle": "^2.0",
        //     "doctrine/doctrine-migrations-bundle": "^3.0"
        // }
        // \`\`\`
        
        // 2. Update your Composer dependencies by running the following command:
        // \`\`\`bash
        // composer update
        // \`\`\`
        
        // 3. Enable the bundles in your \`app/AppKernel.php\` file:
        // \`\`\`php
        // // app/AppKernel.php
        // public function registerBundles()
        // {
        //     $bundles = [
        //         // ...
        //         new Doctrine\\Bundle\\DoctrineBundle\\DoctrineBundle(),
        //         new Sensio\\Bundle\\FrameworkExtraBundle\\SensioFrameworkExtraBundle(),
        //         // ...
        //         new Doctrine\\Bundle\\MigrationsBundle\\DoctrineMigrationsBundle(),
        //     ];
        // }
        // \`\`\`
        
        // 4. Configure Doctrine in your \`app/config/config.yml\` file:
        // `;
        

				const botResponse = await chatWithBot(input);
				const cleanedString = botResponse.replace(/^"|"$/g, "")
        .replace(/\n/g, '<br>') 
        .replace(/ \n /g, '<br>') 
        .replace(/\n\n/g, '<br> <br>') 
        .replace(/\\n/g, '<br>').replace(/ \n/g, '<br>') 
        .replace(/ \n /g, '<br>')
        .replace(/ \n \n/g, '<br> <br>').replace(/\n \n /g, '<br> <br>')
        .replace(/ ?\n\n/g, '<br> <br>').replace(/.\n \n /g, '<br> <br>')
        .replace(/```(.*?)```/gs, '<code>$1</code>')
        .replace(/\`(.+?)\`/g, '<code>$1</code>'); 

				simulateBotResponse(cleanedString, messageId);
			} catch (error) {
				console.error("Error in handleSendMessage:", error);
				setMessages((prev) => [
					...prev,
					{ text: "Failed communicating with the bot.", sender: "bot" },
				]);
			} finally {
				setInput("");
			}
		}
	};

	const simulateBotResponse = (response, messageId) => {
		let accumulatedResponse = "";
		let currentIndex = 0;

		const intervalId = setInterval(() => {
			accumulatedResponse += response[currentIndex];
			currentIndex += 1;

			setMessages((prev) => {
				return prev.map((message) => {
					if (message.id === messageId) {
						return { ...message, text: accumulatedResponse };
					}
					return message;
				});
			});

			if (currentIndex >= response.length) {
				clearInterval(intervalId);
				setIsSending(false);
				setIsTyping(false); 
			}
		}, 30);
	};

	const startListening = () => {
		if (recognition) {
			setIsRecording(true);
			recognition.start();
		}
	};

	const stopListening = () => {
		if (recognition) {
			recognition.stop();
			setIsRecording(false);
      setRecordingTime(0);

			handleSendMessage();
		}
	};

  const cancelListening = () => {
		if (recognition) {
			recognition.stop();
			setIsRecording(false);
      setRecordingTime(0);
				setInput("");


			// handleSendMessage();
		}
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleSendMessage();
		}
	};

	const chatWithBot = async (query) => {
		try {
			const response = await fetch(
				"https://hg6635q5zd64nx-8080.proxy.runpod.net/chat",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ query: query }),
				}
			);

			if (!response.ok) {
				throw new Error(`Error: ${response.statusText}`);
			}

			const reader = response.body.getReader();
			const decoder = new TextDecoder("utf-8");
			let result = "";

			while (true) {
				const { done, value } = await reader.read();
				if (done) break;
				result += decoder.decode(value, { stream: true });
			}

			return result || "No reply received.";
		} catch (error) {
			console.error("Failed to communicate with bot:", error);
			return "failed communicating with bot.";
		}
	};

	useEffect(() => {
		const updateVoices = () => {
			const availableVoices = window.speechSynthesis.getVoices();
			setVoices(availableVoices);
			if (availableVoices.length > 0 && !voiceURI) {
				setVoiceURI(availableVoices[0].voiceURI);
			}
		};

		updateVoices();
		window.speechSynthesis.onvoiceschanged = updateVoices;
	}, [voiceURI]);

// 	const speakText = (text, messageId) => {
// 		window.speechSynthesis.cancel();


// 		const chunks = text.replace(/^"|"$/g, "").replace(/\n/g, '<br>') 
//     .replace(/\n\n/g, '<br>') 
//     .replace(/```(.*?)```/gs, '<pre><code>$1</code></pre>')
//     .replace(/\`(.+?)\`/g, '<code>$1</code>')
// .split(/(?<=[.?!])\s+|\n+/g);

// 		const utteranceQueue = chunks.map((chunk) => {
// 			const utterance = new SpeechSynthesisUtterance(chunk.trim());
// 			const selectedVoice = voices.find((voice) => voice.voiceURI === voiceURI);
// 			if (selectedVoice) {
// 				utterance.voice = selectedVoice;
// 			}
// 			utterance.pitch = pitch;
// 			utterance.rate = rate;
// 			utterance.volume = volume;
// 			return utterance;
// 		});

// 		const speakNext = () => {
// 			if (utteranceQueue.length > 0) {
// 				const utterance = utteranceQueue.shift();
// 				utterance.onend = speakNext;
// 				window.speechSynthesis.speak(utterance);
// 			} else {
// 				setPlayingMessageId(null); 
// 			}
// 		};

// 		setPlayingMessageId(messageId); 
// 		speakNext();
// 	};
const speakText = (text, messageId) => {
  // Cancel any ongoing speech
  window.speechSynthesis.cancel();

  // Clean the input string by replacing unnecessary HTML tags and line breaks
  const cleanedString = text
    .replace(/^"|"$/g, "") // Remove quotes at the start and end
    .replace(/<br\s*\/?>/g, " ") // Replace <br> and <br/> with spaces
    .replace(/<pre><code>(.*?)<\/code><\/pre>/gs, "$1") // Extract content from code blocks
    .replace(/<code>(.*?)<\/code>/g, "$1") // Extract content from inline code
    .replace(/\n+/g, " ") // Replace line breaks with spaces
    .replace(/([.?!])\s+/g, "$1|") // Split sentences by punctuation
    .split("|") // Split the text into chunks based on punctuation
    .map(sentence => sentence.trim()) // Trim any leading or trailing spaces
    .filter(sentence => sentence.length > 0); // Filter out any empty strings

  // Prepare a queue of utterances based on the cleaned string
  const utteranceQueue = cleanedString.map((chunk) => {
    // Ensure the chunk isn't too long for speech synthesis
    if (chunk.length > 200) {
      chunk = chunk.substring(0, 200); // Truncate if necessary
    }

    // Create the SpeechSynthesisUtterance for each chunk
    const utterance = new SpeechSynthesisUtterance(chunk);
    const selectedVoice = voices.find((voice) => voice.voiceURI === voiceURI);
    if (selectedVoice) {
      utterance.voice = selectedVoice; // Set the selected voice if available
    }
    utterance.pitch = pitch; // Set pitch
    utterance.rate = rate;   // Set rate
    utterance.volume = volume; // Set volume

    return utterance;
  });

  // Function to speak the next utterance in the queue
  const speakNext = () => {
    if (utteranceQueue.length > 0) {
      const utterance = utteranceQueue.shift(); // Get the next utterance
      utterance.onend = speakNext; // Set onend to continue to the next utterance
      utterance.onerror = (event) => {
        console.error('SpeechSynthesis error:', event); // Log errors
        speakNext(); // Continue to the next utterance on error
      };
      window.speechSynthesis.speak(utterance); // Speak the current utterance
    } else {
      setPlayingMessageId(null); // Reset the playing message ID when done
    }
  };

  setPlayingMessageId(messageId); // Set the current message ID
  speakNext(); // Start speaking the utterances
};


	const stopAudio = () => {
		window.speechSynthesis.cancel();
		setPlayingMessageId(null); 
	};



  
  useEffect(() => {
    let timer;
    if (isRecording) {
      timer = setInterval(() => setRecordingTime(prev => prev + 1), 1000);
    }
    return () => clearInterval(timer);
  }, [isRecording]);

  const startRecording = () => {
    setIsRecording(true);
    setRecordingTime(0);
    // Start recording logic
  };

  const stopRecording = () => {
    setIsRecording(false);
    // Stop recording logic
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const WaitingMessageAnimation = () => (
		<div className="dot-pulse">
			<span></span>
			<span></span>
			<span></span>
		</div>
	);
	return (
		<div className="chat-container">
			<div className={isRecording ? "chat-box-recording":"chat-box"} ref={chatBoxRef}>
				{messages.map((msg, index) => (
					<div className="msg" key={index}>
						<div className={`message ${msg.sender}`}>{msg.text === "..." ? (
								<WaitingMessageAnimation />
							) : (
								// msg.text
                <div
        id="formattedText"
        dangerouslySetInnerHTML={{ __html: msg.text }}
      />
							)}</div>
						{msg.sender === "bot" && msg.text.length > 40 && !isTyping && (
							<button
								onClick={() => {
									playingMessageId === index
										? stopAudio()
										: speakText(msg.text, index);
								}}
								className="speakbtn"
							>
								<i
									className={`fa-solid ${
										playingMessageId === index
											? "fa-circle-stop"
											: "fa-volume-high"
									}`}
								></i>
							</button>
						)}
					</div>
				))}
			</div>

			<div className="input-container">
				<input
					type="text"
					value={input}
					onChange={(e) => setInput(e.target.value)}
					onKeyPress={handleKeyPress}
					placeholder="Type your message or speak..."
					disabled={isTyping} 
				/>
				{!isRecording && <button
					className="send-btn"
					onClick={handleSendMessage}
					disabled={isTyping || isSending}
				>
					<i className="fa-solid fa-arrow-up-long"></i>
				</button>}

				<button
					onClick={isRecording ? cancelListening : startListening}
					disabled={isTyping || isRecording}
          className="mic-button"
				>
					<i className={`fas fa-microphone${isRecording ? "-slash" : ""}`} />
				</button>
			</div>
      {isRecording && (
        <div className="recording-overlay">
          <p className="timer">{formatTime(recordingTime)}</p>
          <div className="stop-button-wrapper">
            <button onClick={stopListening} className="stop-recording-button">
              <div className="circle-icon">
                <i className="fas fa-square"></i>
              </div>
              <p>Tap to stop recording</p>
            </button>
          </div>
        </div>
      )} 
		</div>
	);
};

export default ChatAudioFile;
