import React from 'react';
import Chat from './components/Chat';
import AudioRecorder from './components/AudioRecorder';
import './App.css'; // Include necessary CSS styles
import { ReactComponent as Logo } from './assets/images/Nexuslink-Logo.svg';
import TextToAudio from './components/TextToAudio';
import ChatAudioFile from './components/ChatAudioFile';

const App = () => {
  const handleAudioSend = (audioData) => {
    console.log('Audio data received:', audioData);
    // Handle sending audio data to the mock WebSocket or process it further
  };

  return (
    <div className="App" style={{ paddingTop: '40px' }}>
      <div className='logo'>
        <Logo width="200px" />
      <h3>Video Content Bot</h3> 
      </div>

      <ChatAudioFile/>

      {/* <Chat /> */}
    </div>
  );
};

export default App;
